<template>
  <div>
      <v-card>
          <v-toolbar>
            <v-toolbar-title>
              {{report.name}}
            </v-toolbar-title>
            <v-spacer>
            </v-spacer>
            <v-btn :to="{ name: 'Reports' }">
              Return to Reports
            </v-btn>
          </v-toolbar>
          <v-data-table :headers="getHeaders" :items="reportData"></v-data-table>
      </v-card>
  </div>
</template>

<script>
export default {
  created() {
    //get the report and run it
    this.$courier.ReportSQL.getById(this.$route.params.reportId).then((x) => {
      this.report = x;

      //once get report - run it
      this.$courier.ReportSQLActions.RunReport(x).then((response) => {
        response.json().then((rData) => {
          this.error = rData.error;

          if (rData.error) {
            this.error = rData.error;
          } else {
            this.reportData = rData.payload;
            console.log(rData);
          }
        });
      });
    });
  },
  computed: {
    getHeaders() {
      if (this.reportData && this.reportData.length > 0) {
        return Object.keys(this.reportData[0]).map((x) => ({
          text: x,
          value: x,
        }));
      } else {
        return [];
      }
    },
  },
  data() {
    return {
      report: {},
      reportData: [],
      error: "",
    };
  },
};
</script>

<style>
</style>