<template>
  <trev-report-view></trev-report-view>
</template>

<script>
import trevReportView from '../../../../components/reports/sql/trev-report-view.vue'
export default {
  components: { trevReportView },

}
</script>

<style>

</style>